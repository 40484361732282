@import "magic";
@import "reset";
:root {
    --header-height: #{rem(100)};
    --header-sticky-height: #{rem(80)};
    --section-outer-gap: #{rem(240)};
    --section-gap: #{rem(120)};
    --section-inner-gap: #{rem(60)};
    --line-height: #{rem(32)};
    --background-shade: #f3f3f5;
    --background-shade-dark: #ABABAB;
    --dark-background-shade: #131317;
    @each $name, $color in $c {
        --#{$name}: #{$color};
    }
    @include mobile {
        --header-height: #{rem(84)};
        --header-sticky-height: #{rem(74)};
        --section-gap: #{rem(60)};
        --section-inner-gap: #{rem(30)};
    }
}
html {
    font: 200 10px / 1.77777777778 $f;
    -webkit-text-size-adjust: 100%;
    background: c(b);
    color: sc(b);
    min-height: 100%;
    display: flex;
    user-select: none;
    letter-spacing: 0.04em;
    overflow-x: hidden;
    overflow-y: auto;
    @include not-tablet {
        @media screen and (max-width: #{$guide}px) {
            font-size: 9.5px;
        }
        @media screen and (max-width: #{1140}px) {
            font-size: 8.5px;
        }
    }
    @media screen and (max-width: 320px) {
        font-size: 8.5px;
    }
    &, body, #root {
        width: 100%;
        @include tablet {
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    &.fslightbox-open {
        overflow-x: hidden;
        overflow-y: auto;
        height: auto;
    }
}
body {
    font-size: rem(18);
    display: flex;
    @include mobile {
        font-size: rem(17);
    }
}
#root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
}
main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.body-background {
    background: c(b);
}
.guide {
    width: 100%;
    margin: auto;
    max-width: rem($guide);
    padding: 0 rem($globalGap);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @include tablet {
        max-width: rem(667 + $globalGap);
    }
}
.inner-guide {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @include not-mobile {
        padding: 0 per(100);
        @include tablet {
            padding: 0 rem(80);
            :not(:first-child) > & {
                padding: 0;
            }
        }
    }
}
.absolutely-splash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    > * {
        width: 100%;
        height: 100%;
    }
}
.m-t-120 {
    margin-top: var(--section-gap);
}
.m-b-120 {
    margin-bottom: var(--section-gap);
}
.m-t-60 {
    margin-top: var(--section-inner-gap);
}
.m-b-60 {
    margin-bottom: var(--section-inner-gap);
}
.grid {
    width: 100%;
    grid-row-gap: rem(30);
    grid-column-gap: rem(30);
    grid-template-columns: 100%;
    @include not-tablet {
        display: grid;
        &.cols-2 {
            grid-template-columns: repeat(2, 1fr);
        }
        &.cols-3 {
            grid-template-columns: repeat(3, 1fr);
        }
        &.cols-4 {
            grid-template-columns: repeat(4, 1fr);
        }
        &.cols-12 {
            grid-template-columns: repeat(12, 1fr);
        }
    }
}
.link-effect {
    position: relative;
    display: inline-block;
    vertical-align: baseline;
    transform: translate3d(0, 0, 0);
    &:after, &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: rem(1);
        background: currentColor;
        transform-origin: right;
        transform: scaleX(1);
        transition: transform .6s cubic-bezier(.165,.84,.44,1) .3s;
    }
    &:after {
        transform-origin: left;
    }
    &:not(:hover):after, &:hover:before {
        transform: scaleX(0);
        transition: transform .6s cubic-bezier(.165,.84,.44,1);
    }
    &.hover-active {
        &:not(:hover):not(.active) {
            &:after {
                transition-delay: 0ms;
            }
            &:before {
                transform: scaleX(0);
            }
        }
    }
}
section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &:not(.ng) {
        &:not(.inner-gap) {
            margin: var(--section-gap) 0;
        }
        &.inner-gap {
            padding: var(--section-gap) 0;
            &.dark-section-follow {
                + .dark-section-follow {
                    padding-top: 0;
                }
            }
        }
    }
    &.background-shade {
        background: var(--background-shade);
    }
    &.dark-background-shade {
        background: var(--dark-background-shade);
        color: c(b);
    }
}
.flex-end {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.flex-justify-space-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-center {
    display: flex;
    align-items: center;
}
.dark-section {
    width: 100%;
    background: sc(b);
    color: c(b);
}
.title {
    font: 900 rem(54) / rem(68) $f;
    @include tablet {
        font: 900 rem(44) / rem(58) $f;
    }
    @include mobile {
        font: 900 rem(38) / rem(46) $f;
    }
}
.title-sub-title {
    font-size: rem(12);
    opacity: 0.5;
    margin-top: rem(20);
}
.m-dark {
    @include over(b, d);
    --background-shade: #09090A;
}
.text-transition {
    .text-transition_inner {
        > div {
            white-space: nowrap;
        }
    }
}
.position-relative {
    position: relative;
}
.overflow-hidden {
    overflow: hidden;
}
.swiper-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}
.swiper-button-next, .swiper-button-prev {
    width: rem(70);
    height: rem(70);
    display: flex;
    justify-content: center;
    align-items: center;
    font: rem(24) / 1 'icomoon';
    background: c(b, 0.3);
    color: sc(b);
    cursor: pointer;
    transition: 300ms color, 300ms background, 400ms transform;
    position: absolute;
    top: 50%;
    z-index: 5;
    transform: translate3d(0, -50%, 0);
    backdrop-filter: blur(rem(20)) brightness(1.5);
    @include hover {
        background: c(b, 0.6);
    }
    &.swiper-button-disabled {
        pointer-events: none;
    }
    @include tablet {
        display: none;
    }
}
.swiper-button-prev {
    left: 0;
    &:before {
        content: '\e906';
    }
}
.swiper-button-next {
    right: 0;
    &:before {
        content: '\e907';
    }
}
.contact-us-space {
    position: relative;
    @include not-mobile {
        margin: -rem(180) 0 0;
    }
}
.skeleton-item {
    --wdith: #{rem(300)};
    width: var(--wdith);
    height: calc(var(--font-size) / 2);
    background: linear-gradient(to right, sc(b, 0.07) 0%, sc(b, 0.05) 50%, sc(b, 0.07) 100%) 0 0 / 200%;
    animation: skeleton-item 0.3s infinite linear;
    border-radius: var(--section-inner-gap);
    .dark-section & {
        background: linear-gradient(to right, c(b, 0.15) 0%, c(b, 0.05) 50%, c(b, 0.15) 100%) 0 0 / 200%;
    }
    .skeleton-group & {
        width: 60%;
        height: rem(2);
        + .skeleton-item {
            margin: var(--line-height) 0 0;
        }
        &:nth-child(odd) {
            width: 90%;
        }
        &:last-child {
            width: 100%;
        }
    }
    &.a-line {
        width: 100%;
        height: rem(1);
        background: linear-gradient(to right, sc(b, 0.15) 0%, sc(b, 0.05) 50%, sc(b, 0.15) 100%) 0 0 / 200%;
    }
    .faq-item-holder:nth-child(odd) &:not(.a-line),
    .review-item-company & {
        width: calc(var(--wdith) / 2);
    }
    .review-item-author & {
        width: calc(var(--wdith) / 1.5);
    }
    .review-date-holder & {
        width: rem(50);
    }
    &.menu-item-c {
        width: rem(50);
        padding: 0;
        &:nth-child(even) {
            width: rem(100);
        }
    }
    &.t-form {
        height: rem(340);
        width: 100%;
        border-radius: 0;
    }
}
@keyframes skeleton-item {
    100% {
        background-position: -200%;
    }
}
.mobile-full-width {
    @include mobile {
        width: calc(100% + #{rem($globalGap * 2)}) !important;
        margin-left:  -rem($globalGap) !important;
        margin-right:  -rem($globalGap) !important;
    }
}
.swiper-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--section-inner-gap) 0 0;
    padding: 0 0 rem(6);
    @include not-tablet {
        display: none;
    }
    .dark-section & {
        @include over(b, d);
    }
    span {
        display: block;
        width: rem(6);
        height: rem(6);
        border-radius: 100%;
        transition: transform 300ms, background 300ms;
        background: sc(b, 0.2);
        + span {
            margin: 0 0 0 rem(30);
            @include mobile {
                margin: 0 0 0 rem(20);
            }
        }
        &.swiper-pagination-bullet-active {
            transform: scale(1.5);
            background: sc(b);
        }
    }
}
