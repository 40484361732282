@function set-color($color, $color-dark: #000, $color-light: #ffffff) {
  @if (lightness($color) > 60) {
    @return mix($color-dark, black);
  } @else {
    @return $color-light;
  }
}
@function c($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color});
  }
  @return rgba(var(--#{$color}-rgb), $opacity);
}
@function ch($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color}-hover);
  }
  @return rgba(var(--#{$color}-rgb-hover), $opacity);
}
@function sc($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color}-sc);
  }
  @return rgba(var(--#{$color}-sc-rgb), $opacity);
}
@mixin over($n1, $n2) {
  --#{$n1}: var(--#{$n2});
  --#{$n1}-hover: var(--#{$n2}-hover);
  --#{$n1}-lighten: var(--#{$n2}-lighten);
  --#{$n1}-darken: var(--#{$n2}-darken);
  --#{$n1}-sc: var(--#{$n2}-sc);
  --#{$n1}-rgb: var(--#{$n2}-rgb);
  --#{$n1}-sc-rgb: var(--#{$n2}-sc-rgb);
}
@function per($size, $dep: 1170) {
  $per: $size / $dep * 100;
  @return #{$per + '%'};
}
@function rem($size) {
  $remSize: $size / 10;
  @if $size == 1 or $size == 0 {
    @return #{$size}px;
  }
  @return #{$remSize}rem;
}
@function -rem($size) {
  $remSize: $size / 10;
  @if $size == 1 {
    @return -#{$size}px;
  }
  @return -#{$remSize}rem;
}
@mixin placeholder {
  $prefixList: '::-webkit-input', ':-moz', '::-moz', ':-ms-input';
  @each $prefix in $prefixList {
    &#{$prefix}-placeholder {
      @content;
      opacity: 1;
    }
  }
}
@mixin retina {
  @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}
@mixin not-retina {
  @media (-webkit-max-device-pixel-ratio: 1) and (max-resolution: 191dpi) {
    @content;
  }
}
@function set-lightness($color, $amount) {
  @if (lightness($color) > 50) {
    @return darken($color, $amount);
  } @else {
    @return lighten($color, $amount);
  }
}
@mixin hover {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}
@mixin hover-active {
    &.active {
        @content;
    }
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}
@mixin not-tablet {
    @media screen and (min-width: 1024px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 1023px) {
        @content;
    }
}
@mixin not-mobile {
    @media screen and (min-width: 667px) {
        @content;
    }
}
@mixin mobile {
    @media screen and (max-width: 666px) {
        @content;
    }
}
