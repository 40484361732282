@import '../../assets/scss/magic';
.splash-loader-holder {
    background: sc(b);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    animation: splash-loader-reveal 400ms;
    @keyframes splash-loader-reveal {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    svg {
        width: 70vw;
        opacity: 0;
        animation: splash-loader-svg-reveal 800ms forwards 200ms;
        transform: translate3d(0, 20%, 0);
        @keyframes splash-loader-svg-reveal {
            to {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
        path {
            stroke: c(b);
            fill: none;
            stroke-miterlimit: 10;
            stroke-dasharray: 500;
            animation: draw 8s infinite backwards;
            stroke-linecap: round;
            transition: stroke-miterlimit 400ms, stroke-dasharray 400ms;
            @keyframes draw {
                0%, 100% {
                    stroke-dashoffset: 0;
                }
                50% {
                    stroke-dashoffset: 2500;
                }
            }
        }
    }
}
