@import "core";

$c: (
    b: #ffffff,
    d: #000000,
    danger: #e00040,
);

@each $name, $color in $c {
    $c: map-merge($c, (#{$name + '-hover'}: set-lightness($color, 5)));
    $c: map-merge($c, (#{$name + '-lighten'}: lighten($color, 10)));
    $c: map-merge($c, (#{$name + '-darken'}: darken($color, 10)));
    $c: map-merge($c, (#{$name + '-sc'}: set-color($color)));
    $c: map-merge($c, (#{$name + '-rgb'}: #{red($color), green($color), blue($color)}));
    $c: map-merge($c, (#{$name + '-sc-rgb'}: #{red(set-color($color)), green(set-color($color)), blue(set-color($color))}));
}

$f: 'Realist', sans-serif;
$fh: 'LunaBar', serif;

$globalGap: 30;


$guide-inner: 1170;
$guide: $guide-inner + $globalGap * 2;

$scrollbar: (
    'size': var(--scrollbar-size, #{rem(16)}),
    'thumbSize': var(--scrollbar-thumbSize, #{rem(8)}),
    'gutter': var(--scrollbar-gutter, #{rem(10)}),
    'space': var(--scrollbar-space, #{rem(4)}),
    'thumbBackground': sc(b, 0.05),
    'thumbActiveBackground': sc(b, 0.1),
);
